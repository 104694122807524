import type { PLAN_ID } from '@/utils/AppConfig';
import type { EnumValues } from './Enum';

export type PlanId = EnumValues<typeof PLAN_ID>;

export const BILLING_INTERVAL = {
  MONTH: 'monthly',
  YEAR: 'yearly',
} as const;

export type BillingInterval = EnumValues<typeof BILLING_INTERVAL>;

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
} as const;

// PricingPlan is currently only used for Pricing section of the landing page.
// If you need a real Stripe subscription payment with checkout page, customer portal, webhook, etc.
// You can check out the Next.js Boilerplate Pro: https://nextjs-boilerplate.com/pro-saas-starter-kit
// On top of that, you'll get access to real example of SaaS application with Next.js, TypeScript, Tailwind CSS, and more.
// You can find a live demo at: https://pro-demo.nextjs-boilerplate.com
export type PricingPlan = {
  id: PlanId;
  prices: {
    monthly: number;
    yearly: number;
  };
  stripeIds: {
    monthly: string | null;
    yearly: string | null;
  };
  testPriceId: string; // Use for testing
  devPriceId: string;
  prodPriceId: string;
  interval: BillingInterval;
  features: {
    teamMember: number;
    basicAppointmentScheduling: boolean;
    customerManagement: boolean;
    limitedSupport: boolean;
    advancedAppointmentScheduling: boolean;
    fullCustomerManagement: boolean;
    employeePerformanceTracking: boolean;
    prioritySupport: boolean;
    marketingTools: boolean;
    unlimitedEmployees: boolean;
    comprehensiveStaffManagement: boolean;
    advancedInventoryTracking: boolean;
    customizableReporting: boolean;
    dedicatedAccountManager: boolean;
    premiumSupport: boolean;
    tailoredMarketingSolutions: boolean;
  };
};

export type IStripeSubscription = {
  stripeSubscriptionId: string | null;
  stripeSubscriptionPriceId: string | null;
  stripeSubscriptionStatus: string | null;
  stripeSubscriptionCurrentPeriodEnd: number | null;
};

export type PlanDetails =
  | {
      isPaid: true;
      plan: PricingPlan;
      stripeDetails: IStripeSubscription;
    }
  | {
      isPaid: false;
      plan: PricingPlan;
      stripeDetails?: undefined;
    };

export const plansColumns = [
  'starter',
  'pro',
  'business',
  'enterprise',
] as const;

export type PlansRow = {
  feature: string;
  starter: boolean | string | null;
  pro: boolean | string | null;
  business: boolean | string | null;
  enterprise: boolean | string | null;
  tooltip?: string;
};

export const comparePlans: PlansRow[] = [
  {
    feature: 'Access to Analytics',
    starter: true,
    pro: true,
    business: true,
    enterprise: 'Custom',
    tooltip: 'All plans include basic analytics for tracking performance.',
  },
  {
    feature: 'Custom Branding',
    starter: null,
    pro: '500/mo',
    business: '1,500/mo',
    enterprise: 'Unlimited',
    tooltip: 'Custom branding is available from the Pro plan onwards.',
  },
  {
    feature: 'Priority Support',
    starter: null,
    pro: 'Email',
    business: 'Email & Chat',
    enterprise: '24/7 Support',
  },
  {
    feature: 'Advanced Reporting',
    starter: null,
    pro: null,
    business: true,
    enterprise: 'Custom',
    tooltip:
      'Advanced reporting is available in Business and Enterprise plans.',
  },
  {
    feature: 'Dedicated Manager',
    starter: null,
    pro: null,
    business: null,
    enterprise: true,
    tooltip: 'Enterprise plan includes a dedicated account manager.',
  },
  {
    feature: 'API Access',
    starter: 'Limited',
    pro: 'Standard',
    business: 'Enhanced',
    enterprise: 'Full',
  },
  {
    feature: 'Monthly Webinars',
    starter: false,
    pro: true,
    business: true,
    enterprise: 'Custom',
    tooltip: 'Pro and higher plans include access to monthly webinars.',
  },
  {
    feature: 'Custom Integrations',
    starter: false,
    pro: false,
    business: 'Available',
    enterprise: 'Available',
    tooltip:
      'Custom integrations are available in Business and Enterprise plans.',
  },
  {
    feature: 'Roles and Permissions',
    starter: null,
    pro: 'Basic',
    business: 'Advanced',
    enterprise: 'Advanced',
    tooltip:
      'User roles and permissions management improves with higher plans.',
  },
  {
    feature: 'Onboarding Assistance',
    starter: false,
    pro: 'Self-service',
    business: 'Assisted',
    enterprise: 'Full Service',
    tooltip: 'Higher plans include more comprehensive onboarding assistance.',
  },
  // Add more rows as needed
];
