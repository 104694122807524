//import type { LocalePrefix } from 'node_modules/next-intl/dist/types/src/shared/types';

import { BILLING_INTERVAL, type PricingPlan } from '@/types/Subscription';
// Define your custom type if needed, or use a simple annotation
type LocalePrefixType = 'always' | 'as-needed' | 'never';

const localePrefix: LocalePrefixType  = 'as-needed';

// FIXME: Update this configuration file based on your project information
export const AppConfig = {
  name: 'SalonEase',
  locales: [
    {
      id: 'en',
      name: 'English',
    },
    { id: 'vi', name: 'Việt Nam' },
  ],
  defaultLocale: 'en',
  localePrefix,
};

export const AllLocales = AppConfig.locales.map((locale) => locale.id);

export const PLAN_ID = {
  FREE: 'Free',
  STARTER: 'Starter',
  PROFESSIONAL: 'Professional',
  BUSINESS: 'Business',
} as const;

export const PricingPlanList: Array<PricingPlan> = [
  {
    id: PLAN_ID.STARTER,
    prices: {
      monthly: 25,
      yearly: 270,
    },
    stripeIds: {
      monthly: 'price_1Pw1UdCmFWLCYysJBlxZ1hBH',
      yearly: 'price_1Pw1VGCmFWLCYysJxNnZPQjq',
    },
    interval: BILLING_INTERVAL.MONTH,
    testPriceId: 'price_1Pw1UdCmFWLCYysJBlxZ1hBH',
    devPriceId: 'price_1Pw1UdCmFWLCYysJBlxZ1hBH',
    prodPriceId: '',
    features: {
      teamMember: 5,
      basicAppointmentScheduling: true,
      customerManagement: true,
      limitedSupport: true,
      advancedAppointmentScheduling: false,
      fullCustomerManagement: false,
      employeePerformanceTracking: false,
      prioritySupport: false,
      marketingTools: false,
      unlimitedEmployees: false,
      comprehensiveStaffManagement: false,
      advancedInventoryTracking: false,
      customizableReporting: false,
      dedicatedAccountManager: false,
      premiumSupport: false,
      tailoredMarketingSolutions: false,
    },
  },
  {
    id: PLAN_ID.PROFESSIONAL,
    prices: {
      monthly: 50,
      yearly: 540,
    },
    stripeIds: {
      monthly: 'price_1PvZbyCmFWLCYysJPTjkyRTu',
      yearly: 'price_1PvZbxCmFWLCYysJOeTTfYv3',
    },
    interval: BILLING_INTERVAL.MONTH,
    testPriceId: 'price_1PvZbyCmFWLCYysJPTjkyRTu',
    devPriceId: 'price_1PvZbyCmFWLCYysJPTjkyRTu',
    prodPriceId: '',
    features: {
      teamMember: 50,
      advancedAppointmentScheduling: true,
      fullCustomerManagement: true,
      employeePerformanceTracking: true,
      prioritySupport: true,
      marketingTools: true,
      basicAppointmentScheduling: true,
      customerManagement: true,
      limitedSupport: false,
      unlimitedEmployees: false,
      comprehensiveStaffManagement: false,
      advancedInventoryTracking: false,
      customizableReporting: false,
      dedicatedAccountManager: false,
      premiumSupport: false,
      tailoredMarketingSolutions: false,
    },
  },
  {
    id: PLAN_ID.BUSINESS,
    prices: {
      monthly: 125,
      yearly: 1350,
    },
    stripeIds: {
      monthly: 'price_1PvZdlCmFWLCYysJGHvbhUux',
      yearly: 'price_1PvZdlCmFWLCYysJMySiWZAn',
    },
    interval: BILLING_INTERVAL.MONTH,
    testPriceId: 'price_1PvZdlCmFWLCYysJGHvbhUux',
    devPriceId: 'price_1PvZdlCmFWLCYysJGHvbhUux',
    prodPriceId: '',
    features: {
      teamMember: 100,
      basicAppointmentScheduling: false,
      customerManagement: true,
      limitedSupport: false,
      advancedAppointmentScheduling: true,
      fullCustomerManagement: true,
      employeePerformanceTracking: true,
      prioritySupport: true,
      marketingTools: true,
      unlimitedEmployees: true,
      comprehensiveStaffManagement: true,
      advancedInventoryTracking: true,
      customizableReporting: true,
      dedicatedAccountManager: true,
      premiumSupport: true,
      tailoredMarketingSolutions: true,
    },
  },
];
