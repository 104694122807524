import Image from 'next/image';

//import { AppConfig } from '@/utils/AppConfig';

const Logo = () => (
  <div className="flex flex-auto items-center text-xl font-semibold">
    <Image src="/logo.png" alt="SalonEase" width="128" height="60" />
    {/* {AppConfig.name} */}
  </div>
);

export { Logo };
